
v-card.poll-common-edit-vote-modal
  submit-overlay(:value="stance.processing")
  v-card-title
    h1.headline
      span(v-if="!stance.castAt", v-t="'poll_common.have_your_say'")
      span(v-if="stance.castAt", v-t="'poll_common.change_your_response'")
    v-spacer
    dismiss-modal-button(:model="stance")

  v-sheet.pa-4
    poll-common-directive(name="vote-form" :stance="stance")
