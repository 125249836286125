
div(style="position: relative")
  v-textarea(
    v-if="!preview"
    filled
    ref="field"
    v-model="model[field]"
    :placeholder="placeholder"
    :label="label"
    @keyup="onKeyUp"
    @keydown="onKeyDown"
    @paste="onPaste"
    @drop="onDrop"
    @dragover.prevent="onDragOver")
  formatted-text(v-if="preview" :model="model" :column="field")
  v-sheet.pa-4.my-4.poll-common-outcome-panel(v-if="preview && model[field].trim().length == 0" color="primary lighten-5" elevation="2")
    p(v-t="'common.empty'")

  v-layout.menubar(align-center :aria-label="$t('formatting.formatting_tools')")
    v-btn(icon @click='$refs.filesField.click()' :title="$t('formatting.attach')")
      v-icon mdi-paperclip
    v-btn(text x-small @click="convertToHtml(model, field)" v-t="'formatting.wysiwyg'")
    v-spacer
    v-btn.mr-4(text x-small @click="preview = !preview" v-t="previewAction")

    slot(name="actions")
  suggestion-list(:query="query" :loading="fetchingMentions" :mentionable="mentionable" :positionStyles="suggestionListStyles" :navigatedUserIndex="navigatedUserIndex" showUsername @select-user="selectUser")

  files-list(:files="files" v-on:removeFile="removeFile")

  form(style="display: block" @change="fileSelected")
    input(ref="filesField" type="file" name="files" multiple=true)
