
.poll-form-page
  v-main
    v-container.max-width-800.px-0.px-sm-3
      v-card.poll-common-modal
        div.pa-4
          poll-template-form(
            v-if="pollTemplate"
            :poll-template="pollTemplate"
          )
