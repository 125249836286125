
.recording-modal
  .pa-4
    .d-flex.justify-space-between
      h1.headline(v-t="'record_modal.record_video'")
      v-btn.dismiss-modal-button(icon :aria-label="$t('common.action.cancel')" @click='dismiss')
        v-icon mdi-close
 
    v-alert(v-if="error" type="error") {{error}}
    div(v-else)
      video(ref="video" width="640" height="360" autoplay muted playsinline)

      .d-flex
        v-spacer
        v-btn.poll-members-form__submit(v-if="!onAir" color="primary" @click="start" v-t="'record_modal.record'")
        v-btn.poll-members-form__submit(v-if="onAir" color="primary" @click="stop" v-t="'record_modal.stop'")
        v-spacer
        v-btn.poll-members-form__submit(v-if="!onAir && url" color="primary" @click="submit" v-t="'common.action.save'")
 
