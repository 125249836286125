
.reply-form(v-if="show")
  //- p reply formwrapper {{eventable.constructor.singular}}

  comment-form(
    :comment="newComment"
    avatar-size="36"
    @comment-submitted="show= false"
    @cancel-reply="show = false"
    autofocus)


