
v-main
  v-container.email-settings-page.max-width-1024.px-0.px-sm-3(v-if='user')

    v-card.mb-4(v-if="user.deactivatedAt")
      //- v-card-title
      //-   h1.headline(tabindex="-1" v-t="'email_settings_page.header'")
      v-card-text
        p(v-t="'email_settings_page.account_deactivated'")

    v-card.mb-4(v-if="!user.deactivatedAt")
      //- v-card-title
      //-   h1.headline(tabindex="-1" v-t="'email_settings_page.header'")
      v-card-text
        .email-settings-page__email-settings
          .email-settings-page__global-settings
            form
              .email-settings-page__global-settings
                v-checkbox#mentioned-email.md-checkbox--with-summary.email-settings-page__mentioned(v-model='user.emailWhenMentioned')
                  div(slot="label")
                    strong(v-t="'email_settings_page.mentioned_label'")
                    .email-settings-page__input-description(v-t="'email_settings_page.mentioned_description'")
                v-checkbox#on-participation-email.md-checkbox--with-summary.email-settings-page__on-participation(v-model='user.emailOnParticipation')
                  div(slot="label")
                    strong(v-t="'email_settings_page.on_participation_label'")
                    .email-settings-page__input-description(v-t="'email_settings_page.on_participation_description'")
                .text-subtitle-1
                  strong(v-t="'email_settings_page.email_catch_up_day'")
                p(v-t="'email_settings_page.daily_summary_description'")
                v-select#email-catch-up-day(
                  solo
                  max-width="100px"
                  :items="emailDays"
                  :label="$t('email_settings_page.email_catch_up_day')"
                  v-model="user.emailCatchUpDay")
                //- v-checkbox#daily-summary-email.md-checkbox--with-summary.email-settings-page__daily-summary(v-model='user.emailCatchUp')
                //-   div(slot="label")
                //-     strong(v-t="'email_settings_page.daily_summary_label'")
                //-     .email-settings-page__input-description(v-t="'email_settings_page.daily_summary_description'")
      v-card-actions
        help-link(path="en/user_manual/users/email_settings/#user-email-settings")
        v-spacer
        v-btn.email-settings-page__update-button(color="primary" @click="submit()" v-t="'email_settings_page.update_settings'")

    change-volume-form.mb-4(:model="user" :show-close="false")

    v-card
      v-card-title
        h1.headline(tabindex="-1" v-t="'email_settings_page.deactivate_header'")
      v-card-text
        p(v-t="'email_settings_page.deactivate_description'")
        v-list
          v-list-item(v-for="(action, key) in actions" :key="key" v-if="action.canPerform()" @click="action.perform()")
            v-list-item-icon
              v-icon {{action.icon}}
            v-list-item-title(v-t="action.name")
