
v-combobox.tags-field__input(
  multiple
  hide-selected
  v-model='model.tags'
  :label="$t('loomio_tags.tags')"
  :items='items'
  )
  template(v-slot:selection='data')
    v-chip.chip--select-multi(
      :key="JSON.stringify(data.item)"
      :value='data.item'
      close
      outlined
      :color='colorFor(data.item)'
      @click:close='remove(data)')
      span {{ data.item }}
  template(v-slot:item='data')
    v-chip.chip--select-multi(outlined :color='colorFor(data.item)' )
      span {{ data.item }}

