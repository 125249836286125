
v-alert(outlined color="primary" dense v-if="isTrialing")
  .d-flex.align-center
    div.pr-1(v-if="isWasGift")
      span(v-if="isExpired" v-html="$t('current_plan_button.was_gift_expired')")
      span(v-if="!isExpired" v-html="$t('current_plan_button.was_gift_remaining', { days: daysRemaining } )")
      space
      span(v-html="$t('current_plan_button.was_gift_trial', {createdDate: createdDate })")
    div.pr-1(v-if="!isWasGift")
      span(v-if="!isExpired" v-t="{ path: 'current_plan_button.free_trial', args: { days: daysRemaining }}")
      span(v-if="isExpired" v-t="'current_plan_button.trial_expired'")
    v-spacer
    v-btn(
      color="primary"
      :href="'/upgrade/'+group.id"
      target="_blank"
      :title="$t('current_plan_button.tooltip')"
    )
      v-icon mdi-rocket
      span(v-t="'current_plan_button.view_plans'")
