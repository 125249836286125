
node-view-wrapper(as="li")
  //- input.flex-shrink-0(style="z-index: 2300" type="checkbox" :checked="node.attrs.checked" @change="onCheckboxChange")
  v-simple-checkbox(contenteditable="false" color="accent" :ripple="false" type="checkbox" :value="checked" @click="onCheckboxChange")
  node-view-content(as="span" :class="{'task-item-text': true, 'task-item-is-empty': isEmpty}" :data-placeholder="$t('tasks.task_placeholder')")
  v-chip.ml-2(v-if="hasMention" contenteditable="false" color="primary" x-small @click="openModal")
    | 📅
    span.ml-1(v-if="node.attrs.dueOn") {{node.attrs.dueOn}}
    span.ml-1(v-else v-t="'tasks.add_due_date'")
  v-dialog(contenteditable="false" ref="dialog" v-model="modalOpen" persistent width="290px")
    v-card
      v-card-title
        span(v-t="'tasks.due_date'")
        v-spacer
        v-btn(icon @click="modalOpen = false")
          v-icon mdi-close
      v-date-picker(v-model="date" no-title scrollable :show-current="false" :min="(new Date()).toISOString().substring(0,10)")
      v-card-text
        v-select(v-model="remind" :label="$t('tasks.send_reminder')" :items="reminders")
      v-card-actions
        v-btn(text color="primary" @click="clearAndClose" v-t="$t('common.action.remove')")
        v-spacer
        v-btn(text color="primary" @click="saveAndClose" v-t="$t('common.action.ok')" :disabled="!date")
