
v-card
  v-card-title
    h1.headline(tabindex="-1" v-t="'announcement.' + modelKind + '_notification_history'")
    v-spacer
    dismiss-modal-button
  v-layout(justify-center)
    v-progress-circular(color="primary" v-if="historyLoading" indeterminate)
  v-card-text(v-if="!historyLoading")
    p(v-if="historyError && historyData.length == 0" v-t="'announcement.history_error'")
    p(v-if="!historyError && historyData.length == 0" v-t="'announcement.no_notifications_sent'")
    p(v-if="historyData.length" v-t="'announcement.notification_history_explanation'")
    div(v-for="event in historyData" :key="event.id")
      h4.mt-4.mb-2
        time-ago(:date="event.created_at")
        mid-dot
        span(v-t="{ path: 'announcement.'+event.kind, args: { name: event.author_name, length: event.notifications.length } }")
      ul(style="list-style-type: none; padding-left: 0")
        li(v-for="notification in event.notifications" :key="notification.id")
          span {{notification.to}}
          space
          span(v-if="notification.viewed") ✓
