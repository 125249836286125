
v-list-item.thread-preview.thread-preview__link(
  :class="{'thread-preview--unread-border': thread.isUnread()}"
  :to='urlFor(thread)'
)
  v-list-item-avatar
    user-avatar(:user='thread.author()', :size='40' no-link)
  v-list-item-content
    v-list-item-title(style="align-items: center")
      span(v-if='thread.pinnedAt', :title="$t('context_panel.thread_status.pinned')")
        v-icon(small) mdi-pin-outline
      span.thread-preview__title(:class="{'thread-preview--unread': thread.isUnread() }") {{thread.title}}
      v-chip.ml-1(x-small label outlined color="warning" v-if='thread.closedAt' v-t="'common.privacy.closed'")
      tags-display.ml-1(:tags="thread.tags" :group="thread.group()" smaller)
    v-list-item-subtitle
      span.thread-preview__group-name(v-if="showGroupName") {{ thread.group().name }}
      mid-dot(v-if="showGroupName")
      span.thread-preview__items-count(v-t="{path: 'thread_preview.replies_count', args: {count: thread.itemsCount}}")
      space
      span.thread-preview__unread-count(v-if='thread.hasUnreadActivity()' v-t="{path: 'thread_preview.unread_count', args: {count: thread.unreadItemsCount()}}")
      mid-dot
      active-time-ago(:date="thread.lastActivityAt")
  v-list-item-action(v-if='$vuetify.breakpoint.mdAndUp')
    action-dock(:actions="dockActions")
  v-list-item-action(v-if='canPerformAny')
    action-menu(:actions="menuActions" icon)
