
v-card.pin-event-form
  v-card-title
    span(v-t="'pin_event_form.title'")
    v-spacer
    dismiss-modal-button(aria-hidden='true')
  v-card-text
    v-form(@submit.prevent="submit()")
      v-text-field(:disabled="loading" ref="focus" v-model="title" :label="$t('pin_event_form.title_label')")
    //- p.caption(v-t="'pin_event_form.hint'")
  v-card-actions
    v-spacer
    v-btn(color="primary" @click="submit()" :loading="loading" v-t="'common.action.save'")
