
v-card.move-thread-form
  submit-overlay(:value='discussion.processing')
  v-card-title
    h1.headline(tabindex="-1" v-t="'move_thread_form.title'")
    v-spacer
    dismiss-modal-button
  v-card-text
    v-select#group-dropdown.move-thread-form__group-dropdown(v-model='discussion.groupId' :required='true' @change='updateTarget()' :items='availableGroups' item-value='id' item-text='fullName' :label="$t('move_thread_form.body')")
      template(v-slot:item='data')
        v-list-item-content.group-dropdown-item
          v-list-item-title.group-dropdown-item-group-name
            span {{ data.item.fullName }}
  v-card-actions
    v-spacer
    v-btn.move-thread-form__submit(color="primary" type='button' v-t="'move_thread_form.confirm'" @click='moveThread()')
