
v-main
  v-container.start-discussion-page.max-width-800.px-0.px-sm-3
    v-card
      discussion-form(
        v-if="discussion"
        :discussion='discussion'
        is-page
        :key="discussion.id"
        :user="user")
