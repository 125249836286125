
v-card.user-name-modal
  submit-overlay(:value='user.processing')
  v-card-title
    h1.headline(tabindex="-1" v-t="'membership_dropdown.set_name_and_username'")
    v-spacer
    dismiss-modal-button
  v-card-text
    v-text-field(:label="$t('profile_page.email_label')" v-model="user.email" disabled)
    v-text-field(:label="$t('profile_page.name_label')" v-model="user.name")
    validation-errors(:subject='user' field='name')
    v-text-field(:label="$t('profile_page.username_label')" v-model="user.username")
    validation-errors(:subject='user', field='username')
  v-card-actions
    v-spacer
    v-btn.primary(@click="submit" v-t="'common.action.save'")
