
div
  .poll-templates-select-group(v-if="selectedGroup.isNullGroup")
    p(v-t="'templates.which_templates_would_you_like_to_use'")
    v-list
      v-list-item(v-for="group in groups" :key="group.id" @click="selectGroup(group)")
        v-list-item-avatar(aria-hidden="true")
          group-avatar(:group="group" v-if="!group.parentId")
        v-list-item-content
          v-list-item-title {{group.name}}
  poll-common-choose-template(
    v-else 
    @setPoll="setPoll"
    :discussion="discussion"
    :group="selectedGroup")
