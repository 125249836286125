
v-navigation-drawer.lmo-no-print.disable-select.thread-sidebar(v-if="discussion" v-model="open" :permanent="$vuetify.breakpoint.mdAndUp" width="230px" app fixed right clipped color="background" floating)
  div.mt-12
  div.strand-nav__toc
    //- | {{items}}
    router-link.strand-nav__entry.text-caption(
      :class="{'strand-nav__entry--visible': item.visible, 'strand-nav__entry--selected': (item.sequenceId == selectedSequenceId || item.commentId == selectedCommentId), 'strand-nav__entry--unread': item.unread}"
      :style="{'border-width': (item.depth * 2)+'px'}"
      v-for="item in items"
      :key="item.key"
      :to="baseUrl+'/'+item.sequenceId")
        .strand-nav__stance-icon-container(v-if="item.poll && item.poll.showResults()")
          poll-common-icon-panel.poll-proposal-chart-panel__chart.mr-1(:poll="item.poll" show-my-stance :size="18" :stanceSize="12")
        //- span {{item.key}}
        span {{item.title}}
