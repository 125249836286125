
.strand-page
  v-main
    v-container.max-width-800.px-0.px-sm-3(v-if="discussion")
      //- p(v-if="$route.query.debug" v-for="rule in loader.rules") {{rule}}
      //- p loader: {{loader.focusAttrs}}
      //- p ranges: {{discussion.ranges}}
      //- p read ranges: {{loader.readRanges}}
      //- p first unread {{loader.firstUnreadSequenceId()}}
      //- p test: {{rangeSetSelfTest()}}
      thread-current-poll-banner(:discussion="discussion")
      discussion-fork-actions(:discussion='discussion', :key="'fork-actions'+ discussion.id")

      strand-card(v-if="loader && loader.firstLoad", :loader="loader")
  strand-toc-nav(v-if="loader", :discussion="discussion", :loader="loader", :key="discussion.id")
