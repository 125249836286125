
div
  v-form.poll-dot-vote-vote-form(ref="form")
    v-banner.poll-dot-vote-vote-form__dots-remaining(sticky rounded dense :color="alertColor" )
      span(v-t="{ path: 'poll_dot_vote_vote_form.dots_remaining', args: { count: dotsRemaining } }")
    .poll-dot-vote-vote-form__options
      v-list-item.poll-dot-vote-vote-form__option(v-for='choice in stanceChoices', :key='choice.option.id')
        v-list-item-content
          v-list-item-title {{ choice.option.name }}
          v-list-item-subtitle(style="white-space: inherit") {{ choice.option.meaning }}
          v-slider.poll-dot-vote-vote-form__slider.mt-4(
            :disabled="!poll.isVotable()"
            v-model='choice.score'
            :color="choice.option.color"
            track-color="grey"
            :height="4"
            :min="0"
            :max="dotsPerPerson"
            :readonly="false")
        v-list-item-action(style="max-width: 128px")
          v-text-field(
            type="number"
            max-width="20px"
            filled
            rounded
            dense
            v-model="choice.score"
          )
      validation-errors(:subject='stance' field='stanceChoices')
    poll-common-stance-reason(:stance='stance', :poll='poll')
    v-card-actions.poll-common-form-actions
      v-btn.poll-common-vote-form__submit(
        block
        @click="submit()"
        :disabled="(dotsRemaining < 0) || !poll.isVotable()"
        :loading="stance.processing"
        color="primary"
      )
        span(v-t="stance.castAt? 'poll_common.update_vote' : 'poll_common.submit_vote'")
