
span
  v-btn.action-button(v-if="action.to" :small="small" :to="action.to()" text :icon="action.dock == 1" :title="text" :class='cssClass' )
    v-icon(v-if="action.dock == 1 || action.dock == 3" :small="small") {{action.icon}}
    span.ml-1(v-if="action.dock == 3")
    span(v-if="action.dock > 1") {{text}}
  v-btn.action-button(v-else :small="small" @click.prevent="action.perform()" text :icon="action.dock == 1" :title="text" :class='cssClass' )
    v-icon(v-if="action.dock == 1 || action.dock == 3" :small="small") {{action.icon}}
    span.ml-1(v-if="action.dock == 3")
    span(v-if="action.dock > 1") {{text}}
