
span.poll-common-stance-choice.text-truncate(:class="'poll-common-stance-choice--' + pollType" row)
  v-avatar(tile :size="size" v-if='poll.config().has_option_icon')
    img(:src="'/img/' + pollOption.icon + '.svg'", :alt='optionName')
  v-chip(v-if='poll.pollOptionNameFormat == "iso8601"'
    outlined :color="colorFor(stanceChoice.score)" @click="emitClick")
    poll-meeting-time(:name="optionName")
  span(v-if='!poll.config().has_option_icon && poll.pollOptionNameFormat != "iso8601"')
    v-icon.mr-2(small :color="pollOption.color") mdi-check-circle
    span {{ optionName }}
