
v-app.app-is-booted
  system-notice
  navbar
  sidebar
  router-view(v-if="!pageError")
  common-error(v-if="pageError" :error="pageError")
  v-spacer
  common-footer
  modal-launcher
  common-flash
