
.poll-form-page
  v-main
    v-container.max-width-800.px-0.px-sm-3
      loading(:until="!loading")
        div.pa-4.py-0(v-if="group")
          .d-flex
            v-breadcrumbs.px-0.pt-0(:items="breadcrumbs")
              template(v-slot:divider)
                v-icon mdi-chevron-right
        v-card.poll-common-modal(v-if="isLoggedIn")
          v-card-title
            h1.text-h5(v-t="'poll_common.decision_templates'")

          poll-common-form.px-4(
            v-if="poll"
            :poll="poll"
            @setPoll="setPoll"
            redirect-on-save
          )

          poll-common-choose-template(
            v-if="!poll"
            @setPoll="setPoll"
            :discussion="discussion"
            :group="group"
          )
