
v-card.auth-complete.text-center(@keyup.ctrl.enter="submit()" @keydown.meta.enter.stop.capture="submit()" @keydown.enter="submit()")
  v-card-title
    h1.headline(tabindex="-1" role="status" aria-live="assertive" v-t="'auth_form.check_your_email'")
    v-spacer
    v-btn.back-button(icon :title="$t('common.action.back')" @click='user.authForm = null')
      v-icon mdi-close
  v-sheet.mx-4
    submit-overlay(:value='loading')
    p.mb-4(v-if='user.sentLoginLink')
      span(v-t="{ path: 'auth_form.login_link_sent', args: { email: user.email }}")
      br
      span(v-t="'auth_form.instructions_code'", v-if='attempts < 3')
    .lmo-validation-error(v-t="'auth_form.too_many_attempts'", v-if='attempts >= 3')
    p.mb-4(v-if='user.sentPasswordLink', v-t="{ path: 'auth_form.password_link_sent', args: { email: user.email }}")
    .auth-complete__code-input.mb-4(v-if='user.sentLoginLink && attempts < 3')
      .auth-complete__code.mx-auto(style="max-width: 256px")
        v-text-field.headline.lmo-primary-form-input(
          outlined
          label="Code"
          :placeholder="$t('auth_form.code')"
          type='integer'
          maxlength='6'
          v-model='user.code'
        )
        //- validation-errors(:subject='session' field='password')
      span(v-t="'auth_form.check_spam_folder'")
  v-card-actions
    v-spacer
    v-btn(
      v-if="!user.hasPassword"
      :disabled='!user.code || loading'
      @click='submitAndSetPassword()'
      v-t="'auth_form.set_password'")
    v-btn(
      color="primary"
      :loading="loading"
      @click='submit()'
      :disabled='!user.code || loading' v-t="'auth_form.sign_in'")
