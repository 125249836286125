
v-card.revision-history-modal
  v-card-title
    h1.headline(tabindex="-1" v-t="'revision_history_modal.' + model.constructor.singular + '_header'")
    v-spacer
    dismiss-modal-button(:close="close")
  .revision-history-modal__body.pa-4
    v-layout(align-center justify-space-between)
      v-btn.revision-history-nav--previous(icon :disabled='isOldest' @click='getPrevious()')
        v-icon mdi-arrow-left

      span(v-if="version" v-t="{path: 'revision_history_modal.edit_by', args: {name: version.authorName(), date: versionDate}}")

      v-btn.revision-history-nav--next(icon :disabled='isNewest' @click='getNext()')
        v-icon mdi-arrow-right
    v-divider.mb-3
    revision-history-content(v-if='version' :model='model' :version='version')
