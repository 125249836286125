
v-card.chatbot-matrix-form
  v-card-title
    h1.headline(tabindex="-1")
      span Webhook
      space
      span(v-t="'chatbot.chatbot'")
    v-spacer
    dismiss-modal-button
  v-card-text
    v-text-field(
      :label="$t('chatbot.name')"
      v-model="chatbot.name"
      hint="The name of your chatroom")
    validation-errors(:subject="chatbot" field="name")
    v-text-field(
      :label="$t('chatbot.webhook_url')"
      v-model="chatbot.server"
      hint="Looks like: https://hooks.example.com/services/abc/xyz/123")
    validation-errors(:subject="chatbot" field="server")

    p.mt-2.mb-2(v-html="$t('webhook.we_have_guides', {url: url})")
    //- v-select(v-model="chatbot.webhookKind" :items="formats" :label="$t('webhook.format')")

    v-checkbox.webhook-form__include-body(
      v-model="chatbot.notificationOnly", 
      :label="$t('chatbot.notification_only_label')" 
      hide-details)
    p.mt-4.text--secondary(v-t="'chatbot.event_kind_helptext'")

    v-checkbox.webhook-form__event-kind(
      hide-details 
      v-for='kind in kinds' 
      v-model='chatbot.eventKinds' 
      :key="kind" 
      :label="$t('webhook.event_kinds.' + kind)" 
      :value="kind")

    v-card-actions
      v-btn(v-if="chatbot.id" @click='destroy' v-t="'common.action.delete'")
      v-spacer
      v-btn(@click='testConnection', v-t="'chatbot.test_connection'", :loading="testing")
      v-btn(color='primary' @click='submit' v-t="'common.action.save'")
