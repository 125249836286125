
v-card.mt-3(outlined).attachment-list-item-link
  a(:href="attachment.download_url" target="_blank")
    v-card-title
      v-icon.mr-2(small) mdi-{{attachment.icon}}
      span.text--secondary
        |{{ attachment.filename }}
        space
        span.text-caption
          |{{ prettifyBytes(attachment.byte_size) }}
    .attachment-list-item-image.mb-2(v-if="attachment.preview_url" :style="{'background-image': 'url('+attachment.preview_url+')', 'background-size': backgroundSize, 'background-position': backgroundPosition}")
