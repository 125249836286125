
.poll-common-chart-panel
  template(v-if="!poll.showResults()")
    v-alert.poll-common-action-panel__results-hidden-until-closed.my-2(
      v-if='!!poll.closingAt && poll.hideResults == "until_closed"'
      dense outlined type="info"
    )
      span(v-t="{path: 'poll_common_action_panel.results_hidden_until_closed', args: {poll_type: poll.pollType}}" )
    v-alert.poll-common-action-panel__results-hidden-until-vote.my-2(
      v-if='!!poll.closingAt && !poll.iHaveVoted() && poll.hideResults == "until_vote"'
      dense outlined type="info"
    )
      span(v-t="'poll_common_action_panel.results_hidden_until_vote'")
  template(v-else)
    template(v-if="poll.config().has_options")
      v-subheader.ml-n4
        span(v-t="poll.closedAt ? 'poll_common.results' : 'poll_common.current_results'")
      poll-common-chart-table(v-if="poll.chartType != 'grid'" :poll="poll")
      poll-common-chart-meeting(v-else :poll="poll")
  poll-common-percent-voted(v-if="poll.pollType != 'count'", :poll="poll")
