
v-card.webhook-list
  v-card-title
    h1.headline(tabindex="-1" v-t="'webhook.api_keys'")
    v-spacer
    dismiss-modal-button(:close="close")
  v-card-text
    p.text--secondary(v-t="'webhook.subtitle'")
    v-alert(type="warning" v-t="'webhook.deprecated'")
    loading(v-if="loading")
    v-list(v-if="!loading")
      v-list-item(v-for="webhook in webhooks" :key="webhook.id")
        v-list-item-content
          v-list-item-title {{webhook.name}}
        v-list-item-action
          action-menu(:actions="webhookActions(webhook)" icon)
  v-card-actions
    help-link(path="en/user_manual/groups/integrations/api")
    v-spacer
    v-btn(color='primary' @click='addAction(group).perform()' v-t="addAction(group).name")
