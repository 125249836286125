
.poll-remind
  .pa-4
    .d-flex.justify-space-between
      h1.headline(v-t="'announcement.form.'+wipOrEmpty+'poll_reminder.title'")
      dismiss-modal-button
    recipients-autocomplete(
      existingOnly
      :label="$t('announcement.form.'+wipOrEmpty+'poll_reminder.helptext')"
      :placeholder="$t('announcement.form.placeholder')"
      :model="poll"
      :reset="reset"
      :excludedUserIds="userIds"
      :excludedAudiences="['group', 'discussion_group']"
      :initialRecipients="initialRecipients")

    v-textarea(
      :label="$t('announcement.form.poll_reminder.message_label')"
      :placeholder="$t('announcement.form.poll_reminder.message_placeholder')"
      v-model="poll.recipientMessage")

    .d-flex
      v-spacer
      v-btn.poll-members-form__submit(color="primary" :disabled="!someRecipients" :loading="saving" @click="submit" )
        span(v-t="'common.action.remind'")
