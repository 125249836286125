
.poll-ranked-choice-vote-form.lmo-relative
  p.text--secondary(v-t="{ path: 'poll_ranked_choice_vote_form.helptext', args: { count: numChoices } }")
  sortable-list(v-model="pollOptions" lock-axis="y" axis="y" append-to=".app-is-booted")
    sortable-item(
      v-for="(option, index) in pollOptions"
      :index="index"
      :key="option.id"
      :item="option"
    )
      v-sheet.mb-2.rounded.poll-ranked-choice-vote-form__option(outlined :style="{'border-color': option.color}")
        v-list-item
          v-list-item-icon
            v-icon(style="cursor: pointer", :color="option.color") mdi-drag
          v-list-item-content
            v-list-item-title {{option.name}}
            v-list-item-subtitle {{option.meaning}}
          v-list-item-action
            span(style="font-size: 1.4rem" v-if="index+1 <= numChoices") # {{index+1}}

  validation-errors(:subject='stance' field='stanceChoices')
  poll-common-stance-reason(:stance='stance', :poll='poll')
  v-card-actions.poll-common-form-actions
    v-btn.poll-common-vote-form__submit(
      block
      :disabled="!poll.isVotable()"
      @click='submit()'
      :loading="stance.processing"
      color="primary"
    )
      span(v-t="stance.castAt? 'poll_common.update_vote' : 'poll_common.submit_vote'")
