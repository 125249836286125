
v-sheet
  poll-common-card-header(:poll='poll')
  div(v-if="poll.discardedAt")
    v-card-text
      .text--secondary(v-t="'poll_common_card.deleted'")
  div.px-2.pb-4.px-sm-4(v-else)
    poll-template-banner(:poll="poll")
    h1.poll-common-card__title.text-h4.py-2(tabindex="-1" v-observe-visibility="{callback: titleVisible}")
      poll-common-type-icon.mr-2(:poll="poll")
      span(v-if='!poll.translation.title') {{poll.title}}
      translation(:model='poll' field='title' v-if="poll.translation.title")
    poll-common-set-outcome-panel(:poll='poll' v-if="!outcome")
    poll-common-outcome-panel(:outcome='outcome' v-if="outcome")
    poll-common-details-panel(:poll='poll')
    poll-common-chart-panel(:poll='poll')
    poll-common-action-panel(:poll='poll')
    action-dock.mt-4(
      :menu-actions="menuActions"
      :actions="dockActions")
    .poll-common-card__results-shown.mt-4
      poll-common-votes-panel(:poll='poll')
