
div.discussions-panel(v-if="group")
  v-layout.py-3(align-center wrap)
    v-menu
      template(v-slot:activator="{ on, attrs }")
        v-btn.mr-2.text-lowercase.discussions-panel__filters(v-on="on" v-bind="attrs" text)
          span(v-t="{path: filterName($route.query.t), args: {count: unreadCount}}")
          v-icon mdi-menu-down
      v-list
        v-list-item.discussions-panel__filters-open(@click="routeQuery({t: null})")
          v-list-item-title(v-t="'discussions_panel.open'")
        v-list-item.discussions-panel__filters-all(@click="routeQuery({t: 'all'})")
          v-list-item-title(v-t="'discussions_panel.all'")
        v-list-item.discussions-panel__filters-closed(@click="routeQuery({t: 'closed'})")
          v-list-item-title(v-t="'discussions_panel.closed'")
        v-list-item.discussions-panel__filters-unread(@click="routeQuery({t: 'unread'})")
          v-list-item-title(v-t="{path: 'discussions_panel.unread', args: { count: unreadCount }}")

    v-menu(offset-y)
      template(v-slot:activator="{ on, attrs }")
        v-btn.mr-2.text-lowercase(v-on="on" v-bind="attrs" text)
          span(v-if="$route.query.tag") {{$route.query.tag}}
          span(v-else v-t="'loomio_tags.tags'")
          v-icon mdi-menu-down
      v-sheet.pa-1
        tags-display(:tags="group.tagNames()" :group="group" :show-counts="!!group.parentId" :show-org-counts="!group.parentId")
    v-text-field.mr-2.flex-grow-1(
      v-model="dummyQuery"
      clearable solo hide-details
      @click="openSearchModal"
      @change="openSearchModal"
      @keyup.enter="openSearchModal"
      @click:append="openSearchModal"
      :placeholder="$t('navbar.search_threads', {name: group.name})"
      append-icon="mdi-magnify")
    v-btn.discussions-panel__new-thread-button(
      v-if='canStartThread'
      v-t="'navbar.start_thread'"
      :to="'/thread_templates/?group_id='+group.id"
      color='primary')

  v-alert(color="info" text outlined v-if="noThreads")
    v-card-title(v-t="'discussions_panel.welcome_to_your_new_group'")
    p.px-4(v-t="'discussions_panel.lets_start_a_thread'")

  v-card.discussions-panel(v-else outlined)
    div(v-if="loader.status == 403")
      p.pa-4.text-center(v-t="'error_page.forbidden'")
    div(v-else)
      .discussions-panel__content
        //- .discussions-panel__list--empty.pa-4(v-if='noThreads')
        //-   p.text-center(v-if='canViewPrivateContent' v-t="'group_page.no_threads_here'")
        //-   p.text-center(v-if='!canViewPrivateContent' v-t="'group_page.private_threads'")
        .discussions-panel__list.thread-preview-collection__container(v-if="discussions.length")
          v-list.thread-previews(two-line)
            thread-preview(:show-group-name="groupIds.length > 1" v-for="thread in pinnedDiscussions", :key="thread.id", :thread="thread" group-page)
            thread-preview(:show-group-name="groupIds.length > 1" v-for="thread in regularDiscussions", :key="thread.id", :thread="thread" group-page)

        loading(v-if="loading && discussions.length == 0")

        v-pagination(v-model="page", :length="totalPages", :total-visible="7", :disabled="totalPages == 1")
        .d-flex.justify-center
          router-link.discussions-panel__view-closed-threads.text-center.pa-1(:to="'?t=closed'" v-if="suggestClosedThreads" v-t="'group_page.view_closed_threads'")

