
.poll-common-stance-reason
  lmo-textarea.poll-common-vote-form__reason(
    :focus-id="'poll-'+poll.id"
    v-if="poll.stanceReasonRequired != 'disabled'"
    :model='stance'
    field="reason"
    :label="$t(label)"
    :placeholder="prompt || poll.reasonPrompt || $t('poll_common.reason_placeholder')"
    :max-length='maxLength'
  )
  validation-errors(:subject="stance" field="reason")

