
div
  label.caption.v-label.v-label--active(style="color: var(--text-secondary)" aria-hidden="true") {{label}}
  .lmo-textarea.pb-1
    collab-editor(
      v-if="format == 'html'"
      :focus-id="focusId"
      :model='model'
      :field='field'
      :placeholder="placeholder"
      :maxLength="maxLength"
      :autofocus="autofocus"
      :shouldReset="shouldReset"
    )
      template(v-for="(_, name) in $scopedSlots", :slot="name" slot-scope="slotData")
        slot(:name="name", v-bind="slotData")
    md-editor(
      v-if="format == 'md'"
      :focus-id="focusId"
      :model='model'
      :field='field'
      :placeholder="placeholder"
      :maxLength="maxLength"
      :autofocus="autofocus"
      :shouldReset="shouldReset"
    )
      template(v-for="(_, name) in $scopedSlots", :slot="name", slot-scope="slotData")
        slot(:name="name" v-bind="slotData")

