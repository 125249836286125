
v-menu
  template(v-slot:activator="{ on, attrs }")
    div.rounded-lg.color-picker-btn
      v-btn.drop-down-button(
        small icon
        :style="{'background-color': buttonBgColor, color: buttonFgColor}" 
        v-on="on"
        v-bind="attrs"
        :title="$t('formatting.colors')"
      )
        v-icon mdi-palette
  v-card.color-picker.pa-2
    .swatch.swatch-color(v-for="(value, key) in colors"
                         :class="{'swatch--selected': key == activeColorKey }"
                         :style="{'background-color': value.lighten1}"
                         @click="editor.chain().setHighlight({color: key}).focus().run()") &nbsp;
    v-btn.mt-2(block x-small outlined @click="editor.chain().unsetHighlight().focus().run()" v-t="'formatting.reset'")
