
v-card.contact-user-modal
  v-card-title
    h1.headline(tabindex="-1" v-t="{ path: 'contact_request_form.modal_title', args: { name: user.name }}")
    v-spacer
    dismiss-modal-button
  v-card-text.contact-user-form
    v-textarea.contact-request-form__message(v-model="contactRequest.message", maxlength="500", :label="$t('contact_request_form.message_placeholder')")
    validation-errors(:subject="contactRequest", field="message")
    p(v-t="{ path: 'contact_request_form.helptext', args: { name: user.firstName() }}")
  v-card-actions
    v-spacer
    v-btn.contact-request-form__submit(@click="submit()" v-t="'common.action.send'" color="primary")
