
v-main
  v-container.inbox-page.thread-preview-collection__container.max-width-1024.px-0.px-sm-3(grid-list-lg)
    h1.display-1.my-4(tabindex="-1" v-observe-visibility="{callback: titleVisible}" v-t="'inbox_page.unread_threads'")
    section.dashboard-page__loading(v-if='unreadCount == 0 && loading' aria-hidden='true')
      .thread-previews-container
        loading-content.thread-preview(:lineCount='2' v-for='(item, index) in [1,2,3,4,5,6,7,8,9,10]' :key='index')
    section.inbox-page__threads(v-if='unreadCount > 0 || !loading')
      .inbox-page__no-threads(v-show='unreadCount == 0')
        span(v-t="'inbox_page.no_threads'")
        span 🙌
      .inbox-page__no-groups(v-show='groups.length == 0')
        p(v-t="'inbox_page.no_groups.explanation'")
        button.lmo-btn-link--blue(v-t="'inbox_page.no_groups.start'", @click='startGroup()')
        | &nbsp;
        span(v-t="'inbox_page.no_groups.or'")
        | &nbsp;
        span(v-html="$t('inbox_page.no_groups.join_group')")
      .inbox-page__group(v-for='group in groups', :key='group.id')
        v-card.mb-3(v-if='views[group.key].length > 0')
          v-card-title
            group-avatar.mr-2(:group="group" :size="40")
            router-link.inbox-page__group-name(:to="'/g/' + group.key")
              span.subheading {{group.name}}
          thread-preview-collection(:threads="views[group.key]", :limit="threadLimit")
        //- strand-wall(:threads="views[group.key]")
