
.strand-new-discussion.context-panel#context(v-observe-visibility="{callback: viewed, once: true}")
  v-layout.ml-n2(align-center wrap)
    v-breadcrumbs.context-panel__breadcrumbs(:items="groups")
      template(v-slot:divider)
        v-icon mdi-chevron-right
    v-spacer
    tags-display(:tags="discussion.tags" :group="discussion.group()")
    v-chip(
      v-if="discussion.private"
      small outlined
      :title="$t('discussion_form.privacy_private')"
      )
      i.mdi.mdi-lock-outline.mr-1
      span(v-t="'common.privacy.private'")
    v-chip(
      v-if="!discussion.private"
      small outlined
      :title="$t('discussion_form.privacy_public')"
      )
      i.mdi.mdi-earth.mr-1
      span(v-t="'common.privacy.public'")

  strand-title(:discussion="discussion")

  .mb-4
    user-avatar.mr-2(:user='author', :size='36')
    router-link.text--secondary(:to="urlFor(author)") {{authorName}}
    mid-dot
    router-link.text--secondary(:to='urlFor(discussion)')
      time-ago(:date='discussion.createdAt')
    span(v-show='discussion.seenByCount > 0')
      mid-dot
      a.context-panel__seen_by_count(v-t="{ path: 'thread_context.seen_by_count', args: { count: discussion.seenByCount } }"  @click="openSeenByModal()")
    span(v-show='discussion.usersNotifiedCount != null')
      mid-dot
      a.context-panel__users_notified_count(v-t="{ path: 'thread_context.count_notified', args: { count: discussion.usersNotifiedCount} }"  @click="actions.notification_history.perform")
  template(v-if="!collapsed")
    formatted-text.context-panel__description(:model="discussion" column="description")
    link-previews(:model="discussion")
    document-list(:model='discussion')
    attachment-list(:attachments="discussion.attachments")
    action-dock.py-2(:model='discussion' :actions='dockActions' :menu-actions='menuActions')
  strand-actions-panel(v-if="discussion.newestFirst" :discussion="discussion")
