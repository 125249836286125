
v-menu
  template(v-slot:activator="{ on, attrs }")
    div.rounded-lg
      v-btn.drop-down-button(small icon v-on="on" :outlined="showOutline" :title="$t('formatting.alignment')")
        v-icon mdi-format-align-{{current}}
  v-list(dense)
    v-list-item(v-for="(item, index) in alignments" :key="index" :class="{ 'v-list-item--active': editor.isActive({ textAlign: item.value }) }" @click="editor.chain().focus().setTextAlign(item.value).run()")
      v-list-item-icon
        v-icon {{'mdi-format-align-'+item.value}}
      v-list-item-title(v-t="item.label")
